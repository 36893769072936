import { z } from 'zod';

export const SETUP = 'SETUP';
export const PLAYER_JOINED = 'PLAYER_JOINED';
export const PLAYER_TURN = 'PLAYER_TURN';
export const PACK_SELECTION_CONFIRMED = 'PACK_SELECTION_CONFIRMED';
export const CARD_SELECTION_CONFIRMED = 'CARD_SELECTION_CONFIRMED';
export const CANCEL_SELECTION = 'CANCEL_SELECTION';
export const GAME_COMPLETE = 'GAME_COMPLETE';
export const POST_GAME_CARD_FOCUS = 'POST_GAME_CARD_FOCUS';
export const POST_GAME_CARD_UPDATE = 'POST_GAME_CARD_UPDATE';
export const END_GAME = 'END_GAME';
export const UNLOCK_AUDIO = 'UNLOCK_AUDIO';

export const MESSAGES = z.enum([
    SETUP,
    PLAYER_JOINED,
    PLAYER_TURN,
    PACK_SELECTION_CONFIRMED,
    CARD_SELECTION_CONFIRMED,
    CANCEL_SELECTION,
    GAME_COMPLETE,
    POST_GAME_CARD_FOCUS,
    POST_GAME_CARD_UPDATE,
    END_GAME,
    UNLOCK_AUDIO,
]);
